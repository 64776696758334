import {
  europeCountryCodes,
  getShopifyCountry,
  getShopifyLanguage,
  locales,
} from "./locales.js";
import { usePageContext } from "vike-react/usePageContext";

export function useIsomorphicPathname() {
  if (typeof location == "undefined") {
    return usePageContext().urlPathname;
  } else {
    return location.pathname;
  }
}

export function useExplicitLocale(explicitOnly = true) {
  const pathname = useIsomorphicPathname();
  const locale = pathname.split("/")[1];
  if (locales.includes(locale)) {
    return locale;
  }
  if (!explicitOnly) {
    return locales[0];
  }
  return null;
}
export function useLocale() {
  return useExplicitLocale(false)!;
}
export function useLocalePathname(bit?: string) {
  const pathname = useIsomorphicPathname();
  const locale = useExplicitLocale();
  if (locale) {
    return "/" + pathname.split(/\//g).slice(2).join("/");
  } else {
    return pathname;
  }
}
export function useLocaleCountry() {
  return getShopifyCountry(useLocale());
}
export function useLocaleLanguage() {
  return getShopifyLanguage(useLocale());
}

export function useShopifyContext() {
  const countryCode = useLocaleCountry();
  const languageCode = useLocaleLanguage();
  return { countryCode, languageCode };
}
export function useLocaleMarket() {
  const countryCode = useLocaleCountry();
  if (countryCode == "ID") {
    return "Indonesia";
  } else if (europeCountryCodes.includes(countryCode)) {
    return "Europe";
  } else {
    return "Worldwide";
  }
}

export function getLocalePathname(path: string) {
  const locale = useExplicitLocale();
  if (locale) {
    return "/" + locale + path;
  } else {
    return path;
  }
}
