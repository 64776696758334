import type { Request } from "express";
import type { ShopifyContext } from "./shopify.js";
import { useLocale } from "./pathnames.jsx";

export const locales = ["en", "en-ID", "id", /*"ru-ID",*/ "id-US"];

export const europeCountryCodes = [
  "AL",
  "AD",
  "AM",
  "AT",
  "BY",
  "BE",
  "BA",
  "BG",
  "HR",
  "CY",
  "CZ",
  "DK",
  "EE",
  "FI",
  "FR",
  "GE",
  "DE",
  "GR",
  "HU",
  "IS",
  "IE",
  "IT",
  "KZ",
  "XK",
  "LV",
  "LI",
  "LT",
  "LU",
  "MT",
  "MD",
  "MC",
  "ME",
  "NL",
  "MK",
  "NO",
  "PL",
  "PT",
  "RO",
  "RU",
  "SM",
  "RS",
  "SK",
  "SI",
  "ES",
  "SE",
  "CH",
  "TR",
  "UA",
  "GB",
  "VA",
];

export function getShopifyContext(locale: string): ShopifyContext {
  if (!locales.includes(locale)) {
    //notFound();
  }
  return {
    countryCode: getShopifyCountry(locale),
    languageCode: getShopifyLanguage(locale),
  };
}

export function getShopifyCountry(locale: string) {
  return (
    locale.split("-")[1] ||
    getShopifyCountryFromLanguage(getShopifyLanguage(locale))
  );
}
export function getShopifyLanguage(locale: string) {
  return locale.split("-")[0]?.toUpperCase() || "EN";
}
export function getShopifyCountryFromLanguage(language: string) {
  return language == "ID" ? "ID" : "US";
}

export function getLocaleFromContext(
  context: ShopifyContext,
  fallback = locales[0]
) {
  return (
    locales.find(
      (l) => l == `${context.languageCode.toLowerCase()}-${context.countryCode}`
    ) ||
    locales.find((l) => l == `${context.languageCode.toLowerCase()}`) ||
    fallback
  );
}

export function getHreflangs(
  path: string
): Record<(typeof locales)[number], string> {
  return Object.fromEntries(
    locales.map((l, i) => {
      if (path.includes("//")) {
        var [origin, pathname] = path.split(/(?<=\/\/[^/]+)\//);
      } else {
        var [origin, pathname] = ["", path.substring(1)];
      }
      return [l, origin + `/${l}`];
    })
  );
}

export function getImplicitLocale(acceptLanguage, cfIpCountry) {
  let language = acceptLanguage || "en";
  let languages = language
    .split(/\s*,\s*/g)
    .map((language) => {
      return language.split("-")[0].toUpperCase();
    })
    .filter((value, index, self) => self.indexOf(value) === index);

  var countryCode = String(cfIpCountry);
  if (countryCode == null || countryCode == "XX" || countryCode == "T1")
    countryCode = "US";

  for (const languageCode of languages) {
    const candidate = getLocaleFromContext(
      {
        languageCode: languageCode,
        countryCode: countryCode,
      },
      null
    );
    if (locales.includes(candidate)) return candidate;
  }
  if (countryCode == "ID") return "en-ID";

  return "en";
}

export function getExplicitLocale(url: string) {
  const locale = url.split(/\//g)[1];
  return locales.includes(locale) ? locale : null;
}

export function getLocalizedPath(url: string, locale: string = useLocale()) {
  if (url.includes("//") || url.includes("mailto:")) return url;
  const path = getCanonicalPath(url);
  return "/" + locale + path;
}

export function getCanonicalPath(url: string) {
  const locale = getExplicitLocale(url);
  return locale ? url.replace("/" + locale, "") || "/" : url;
}
